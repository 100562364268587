import React from 'react';
import { useQuery } from '@apollo/client';
import { Container, Row, Col } from "react-bootstrap"
import gql from "graphql-tag"

import SimilarProperty from "./SimilarProperty";

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    properties(where:{crm_id: $id}) {
      id
      title
      crm_id
      display_address
      search_type
      images
      area
      imagetransforms
    }
  }
`;


const PropertyDetailsTemplate = (props) => {

  // 
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0];
  let property_slug = mypageurl.split('-');
  let id = property_slug[property_slug.length - 1];
  id = id.replace("/", "");

  const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
    variables: { id: id }, context: { clientName: "feed" }
  });
  // 
  if (loading) return (
    <section className={"loader-wrapper"}>
      <div id="loader-wrapper">
        <div id="loader" class="new-loader">
          
        </div>
      </div>
    </section>
  );



  return (
    <React.Fragment>
      {property_details && property_details.properties && property_details.properties.length > 0 ? property_details.properties.map((data, i) => {
        return (
          <>
              <SimilarProperty class="recent-updated-properties" propertyData={data} />
          </>
        )
      }) : ''}
    </React.Fragment>
  )
}

export default PropertyDetailsTemplate;
